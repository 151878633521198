import React from "react"

import SEO from "../components/seo"

import Header from "../components/header"

const NotFoundPage = () => (
  <>
    <SEO/>
    <Header/>
    <article>
      <div class="container" style={{margin:'2rem auto'}}>
        <h1>NOT FOUND</h1>
        <p>You went down the wrong path.</p>
        <p><a href="/">Start over</a></p>
      </div>
    </article>
  </>
)

export default NotFoundPage
